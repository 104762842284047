@import './fonts';
@import './assets/styles/variables';
@import './assets/styles/utility';
@import './assets/styles/common';
@import './assets/styles/overwrite';
@import './assets/styles/mixin';
@import '~@safire/theme/all';
@import '~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@include sfMargin($css-box-module-values, $sf-sides);
@include sfPadding($css-box-module-values, $sf-sides);
@include sfFontSize($css-box-module-values);
@include sfValign;

.filter-modal {
  position: absolute !important;
  right: 0;
  height: 100%;
  max-width: 100vw !important;
  @include sf-media('xs') {
    .sf-dialog__primary {
      width: 100vw !important;
      border-top: none !important;
      border-radius: 0 !important;
    }
  }
}

sf-dialog-container {
  width: 100%;
}

.custom-error {
  display: block;
  position: relative;
  margin-left: 8px;

  & > span {
    display: block;
    position: absolute;
    margin-top: -12px;
    color: var(--sf-error--dark);
  }
}

.sf-button {
  min-height: 36px;
}

.mdc-text-field {
  height: 42px;

  &--textarea {
    height: auto !important;
  }

  &.mdc-text-field--outlined .mdc-floating-label {
    top: 12px;
  }

  &.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  &.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  &.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  &.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-100%) translateX(-32px) scale(0.75);
  }

  &.mdc-text-field--with-leading-icon .mdc-text-field__icon,
  &.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
    bottom: 8px;
  }

  &.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  &.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-100%) scale(0.75);
  }
}

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
  width: 0 !important;
  background: transparent;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
