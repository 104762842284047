@import 'variables';
@import 'mixin';

.width--full {
  width: 100%;
}
.width--half {
  width: 48%;
}
.bg {
  &--white {
    background-color: var(--sf-white);
  }
  &--success {
    background: rgba($sf-success, 0.24);
  }
  &--primary-20 {
    background: rgba($sf-primary, 0.2);
  }
  &--primary-16 {
    background: rgba($sf-primary, 0.16) !important;
  }
  &--black-10 {
    background-color: rgba($sf-black, 0.1) !important;
  }
  &--black-05 {
    background-color: rgba($sf-black, 0.05) !important;
  }
  &--black-03 {
    background-color: rgba($sf-black, 0.03) !important;
  }

  &--primary {
    background: $sf-primary !important;
  }
  &--primary-24 {
    background: rgba($sf-primary, 0.24);
  }
  &--primary-06 {
    background: rgba($sf-primary, 0.06);
  }
  &--black-20 {
    background-color: rgba($sf-black, 0.20) !important;
  }
  &--black-38 {
    background-color: rgba($sf-black, 0.38) !important;
  }
  &--black-87 {
    background-color: rgba($sf-black, 0.87) !important;
  }
  &--mandys-pink {
    background-color: $mandys-pink;
  }
  &--mandys-pink-02 {
    background-color: rgba($mandys-pink, 0.2);
  }
  &--blueish-white {
    background-color: var(--sf-secondary);
  }
  &--sushi-green-02 {
    background-color: rgba($sushi-green, 0.2);
  }
  &--whisper-white {
    background-color: $whisper-white;
  }
  &--cyan-blue-02 {
    background-color: rgba($cyan-blue, 0.2);
  }
  &--dark-orange-02 {
    background-color: rgba($dark-orange, 0.2);
  }
  &--golden-dream-02 {
    background-color: rgba($golden-dream, 0.2);
  }
  &--jade-green-02 {
    background-color: rgba($jade-green, 0.2);
  }
  &--baby-pink-05 {
     background-color: rgba($baby-pink, 0.5);
   }
  &--baby-pink-03 {
    background-color: rgba($baby-pink, 0.3);
  }
}

.color {
  &--primary {
    color: $sf-primary;
  }
  &--primary-20 {
    color: rgba($sf-primary, 0.2);
  }
  &--primary-60 {
    color: rgba($sf-primary, 0.6);
  }
  &--primary-87 {
    color: rgba($sf-primary, 0.87);
  }
  &--success {
    color: var(--sf-success);
  }
  &--black-38 {
    color: rgba($sf-black, 0.38);
  }
  &--black-60 {
    color: rgba($sf-black, 0.6);
  }
  &--black-54 {
    color: rgba($sf-black, 0.54);
  }
  &--black-80 {
    color: rgba($sf-black, 0.80);
  }
  &--black-87 {
    color: rgba($sf-black, 0.87);
  }
  &--white {
    color: var(--sf-white) !important;
  }
  &--black-20 {
    color: rgba($sf-black, 0.2);
  }
  &--black-38 {
    color: rgba($sf-black, 0.38);
  }

  &--sushi-green {
    color: $sushi-green;
  }
  &--dark-orange {
    color: $dark-orange;
  }
  &--golden-dream {
    color: $golden-dream;
  }
  &--jade-green {
    color: $jade-green;
  }
  &--muddy-green {
    color: $muddy-green;
  }
  &--cyan-blue {
    color: $cyan-blue;
  }
}

.font {
  &--600 {
    font-weight: 600;
  }
  &--italic {
    font-style: italic;
  }
}

.cursor--pointer {
  cursor: pointer;
}

.border {
  &--all {
    border: 2px solid rgba($sf-black, 0.04);
  }
  &--none {
    border: none;
  }
  &--primary {
    border-color: $sf-primary;
  }
  &--all-black {
    border: 1px solid rgba($sf-black, 0.4);
  }
  &--black-12 {
    border: 1px solid rgba($sf-black, 0.12);
  }
  &--top-orange {
    border-top: 2px solid $dark-orange;
  }
  &--top-green {
    border-top: 2px solid $jade-green;
  }
  &--top-golden {
    border-top: 2px solid $golden-dream;
  }
  &--primary-all {
    border: 1px solid $sf-primary;
  }
  &--bottom-primary {
    border-bottom: 1px solid $sf-primary;
  }
}

.trailing-icon {
  width: 20px;
  margin-top: -36px;
  margin-left: 300px;
  z-index: 2;
}

.trailing-email {
  width: 20px;
  margin-top: -50px;
  margin-left: 386px;
  z-index: 2;
}

.ml {
  &-N4 {
    margin-left: -4px;
  }
  &-N8 {
    margin-left: -8px;
  }
  &-N12 {
    margin-left: -12px;
  }
  &-N32 {
    margin-left: -32px;
  }
}

.mb {
  &-N20 {
    margin-bottom: -20px;
  }
  &-N12 {
    margin-bottom: -12px;
  }
}

/* Chrome, Safari, Edge, Opera */
::ng-deep {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none !important;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield !important;
  }

  sf-input.mdc-text-field {
    height: 42px !important;
  }

  .sf-select {
    height: 42px !important;
  }

  .mdc-text-field--with-leading-icon .mdc-text-field__icon,
  .mdc-text-field--with-trailing-icon .mdc-text-field__icon {
    bottom: 8px;
  }

  .mdc-text-field .mdc-floating-label {
    top: 12px;
  }

  .mdc-data-table__header-cell {
    color: rgba($sf-black, 0.6);
    font-size: 12px;
    width: 20%;

  }

  .mdc-data-table__row {
    height: 60px;
  }

  label.mdc-floating-label {
    font-size: 14px;
  }
  .mdc-switch.mdc-switch--checked .mdc-switch__track {
    background-color: var(--sf-primary) !important;
    opacity: 1;
  }
  .mdc-floating-label--float-above {
    font-size: 14px !important;
  }
}

ul {
  li::marker {
    color: $sf-primary;
  }
}

.text {
  &--center {
    text-align: center;
  }
  &--justify {
    text-align: justify;
  }
  &--truncate {
    width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &--wrap {
    word-wrap: break-word;
  }
  &--nowrap {
    white-space: nowrap;
  }
  &--right {
    text-align: right;
  }
}

.display--none {
  display: none;
}

.mt {
  &-N20 {
    margin-top: -20px;
  }
  &-N16 {
    margin-top: -16px;
  }
}

.mr {
  &-N5 {
	margin-right: -5px;
  }
  &-N6 {
	margin-right: -6px;
  }
  &-N16 {
    margin-right: -16px;
  }
}

.ew-error {
  color: $sf-primary !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $sf-primary !important;
}
