@import '~@safire/theme/utilities';

// SAFIRE Variables
$sf-primary: #C32B1F;
$sf-secondary: #F5F6FA;
$sf-black: #000000;
$sf-success: #00C256;
$sf-warning: #FFE338;
$sf-orange: #F28C10;
$sf-font: 'Open Sans';

//body color
$whisper-white: #F5F6FA;
$mandys-pink: #F5C9C6;
$sushi-green: #9DBF41;
$dark-orange: #F28C10;
$golden-dream: #EBCD34;
$jade-green: #00C256;
$cyan-blue: #2B6FB0;
$muddy-green: #606B40;
$alizarin-crimson: #E6332A;
$baby-pink: #F6C8C5;
$light-grey: #D9D9D9;
$carmine: #B00020;
$light-orange: #F39C12;
$eyes-blue: #0166B3;

$sf-radius: 4px;
$font-size: (12, 14, 16, 20, 24, 40);
$spaceamounts: (0, 4, 8, 12, 16, 20, 24);
$sides: (top, bottom, right, left);
$css-box-module-values: (0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 40, 42, 46, 48, 50, 52, 58, 80);
