@import '~@safire/theme/utilities';

html {
  touch-action: pan-x pan-y;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

.sf-snack-bar__content {
  display: flex;
  align-items: center;
  width: 100%;

  sf-icon.sf-icon.material-icons {
    margin: 0 0 0 6px;
    width: fit-content !important;
  }

  .sf-simple-snackbar__action-btn.mdc-button:not(:disabled) {
    margin-top: 12px;
    display: flex;
    justify-content: end;
  }
}

.sf-accordion-item {
  overflow: visible !important;
}

.sf-button {
  min-width: 50px !important;
  height: 36px;
  text-transform: capitalize !important;
}

.sf-dialog__container {
  border-radius: $sf-radius + 4px !important;
}

.mdc-list {
  font-size: 14px !important;
}

.sf-accordion-icon {
  right: 0 !important;
}

::ng-deep .mdc-tab {
  text-transform: capitalize;
}

.sf-flat-tab {
  border-bottom: none !important;
}

.mdc-tab {
  text-transform: capitalize !important;
}

.sf-avatar__img {
  object-fit: inherit !important;
}

.sf-paginator {
  &__container {
    margin-top: 16px;
  }

  .mdc-select {
    &__dropdown-icon {
      bottom: 4px !important;
    }

    &__native-control,
    &__selected-text {
      padding-top: 10px;
    }
  }
}

.product-change__image {
  width: 24px;
  height: 24px;
  border-radius: 50px;
}

.custom-auto-complete {
  .sf-autocomplete {
    width: 100% !important;
    height: 54px !important;
    box-shadow: none !important;

    &__menu ul {
      position: fixed;
      width: 30%;
      max-height: 160px;
      z-index: 1;
    }

    &__menu.sf-is-visible {
      border-radius: 4px !important;

      ul {
        border-radius: 4px !important;
        background-color: var(--sf-white);
        box-shadow: 0 2px 1px -1px rgba($sf-black, 0.2), 0 1px 1px 0 rgba($sf-black, 0.14), 0 1px 3px 0 rgba($sf-black, 0.12);
      }
    }
  }
}

.owl-dt-inline-container .owl-dt-calendar, .owl-dt-popup-container .owl-dt-calendar {
  height: auto !important;
}

.hide-visibility {
  visibility: hidden !important;
}

.notification__snack-bar {
   .sf-snack-bar__success {
      background-color: $cyan-blue !important;
       .material-icons {
         display: none;
       }
   }
}


#ecLauncherFloatingBubble {
  bottom: 20px !important;
  right: 32px !important;

  @include sf-media('lt-sm') {
    right: 20px !important;
  }
}