@mixin boxModel($values, $className, $direction) {
  @each $size in $values {
    #{'.'+$className +'--'+ $size} {
      @each $property in $direction {
        #{$property}: #{$size}px;
      }
    }
  }
}

@mixin iconModel($values, $className, $direction) {
  @each $size in $values {
    #{'.'+$className +'-'+ $size} {
      @each $property in $direction {
        #{$property}: #{$size}px;
      }
    }
  }
}

@include boxModel((0, 4, 8, 20, 30, 50), 'br', 'border-radius');
@include boxModel((0, 4, 8), 'brl', ('border-top-left-radius', 'border-bottom-left-radius'));
@include boxModel((0, 4, 8), 'brr', ('border-top-right-radius', 'border-bottom-right-radius'));
@include boxModel((0, 4, 8), 'brt', ('border-top-right-radius', 'border-top-left-radius'));
@include boxModel((0, 4, 8), 'brtr', 'border-top-right-radius');
@include boxModel((0, 4, 8), 'brtl', 'border-top-left-radius');
@include boxModel((0, 4, 8), 'brtlr',('border-top-left-radius', 'border-top-right-radius'));
@include boxModel((0, 4, 8), 'brblr',('border-bottom-left-radius', 'border-bottom-right-radius'));
@include iconModel((12, 14, 16, 18, 20, 24, 30, 36, 38, 40, 48), 's', ('font-size' ,'height', 'width'));
