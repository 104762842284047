@import 'utility';
@import 'mixin';
@import '~@safire/theme/utilities';

body {
  margin: 32px;
  background-color: $whisper-white;
  font-size: 14px !important;
  line-height: 20px;
  @include sf-media('lt-md') {
    margin: 0;
  }
  @include sf-media('gt-md') {
    overflow: hidden;
  }
  @include sf-media('lt-sm') {
    .sf-typography--headline6 {
      font-size: 16px;
    }

  }
}

a {
  color: var(--sf-primary);
  &:not(.link-underline) {
    text-decoration: none;
  }
}

p {
  line-height: 1.8;
}

.auth {
  &__top-circle {
    position: fixed;
    top: 0;
    left: 0;
    width: 332px;
    height: 280px;
    background: url('/assets/images/top.svg');
    background-repeat: no-repeat;
    z-index: -4;
  }

  &__bottom-circle {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 332px;
    height: 280px;
    transform: rotate(180deg);
    background: url('/assets/images/top.svg');
    background-repeat: no-repeat;
    z-index: -1;
  }

  &__container {
    width: 100%;
    min-height: calc(100vh - 120px);
    @include sf-media('xs') {
      min-height: 100vh;
    }
    &-logo {
      height: 60px;
    }
  }
}

.product-item__image {
  max-width: 80px;
  max-height: 80px;
}

.active-portal {
  background-color: rgba($sf-success, 0.24);
  color: $sf-success;
}

.auth__container__signin-svg {
  @include sf-media('lt-md') {
    width: 200px;
  }
}

.sf-typography--headline6 {
  @include sf-media('lt-md') {
    font-size: 16px !important;
  }
}

.sf-paginator__page-size {
  display: none !important;
}

.common-modal--width {
  @include sf-media('xs') {
    height: 100% !important;
    max-width: 100% !important;
    .sf-dialog__content {
      max-height: calc(100vh - 224px);
      min-height: calc(100vh - 250px);
      padding-bottom: 24px;
    }
    .sf-dialog__container {
      max-width: 100% !important;
      min-width: 100% !important;
      height: 100% !important;
      border-radius: 0;
    }

    .sf-dialog__primary {
      border-top: none !important;
      border-radius: 0 !important;
    }
  }
}

.add-address-modal {
  @include sf-media('xs') {
    height: 100% !important;
    max-width: 100% !important;
  }
}

.email-address-modal {
  @include sf-media('xs') {
    height: auto !important;
    max-width: 90vw !important;
  }
}
.auth-resend-button.sf-button--primary.mdc-ripple-upgraded {
  text-transform: none !important;
}

.common-modal__button.mdc-button.mdc-ripple-upgraded {
  text-transform: none !important;
}

::ng-deep {
  @include sf-media('xs') {
    .pc-modal--width {
      width: 100% !important;
      height: 100% !important;

      .sf-dialog {
        &__container {
          width: 100% !important;
          border-radius: 0 !important;
        }
        &__primary {
          border-top: none !important;
        }
        &__content {
          max-height: calc(100vh - 205px);
          padding-bottom: 24px;
        }
      }
      .common-action__button {
        width: 88%;
      }
    }

    .common-action__button {
      position: fixed !important;
      width: 84% !important;
      bottom: 24px !important;
      z-index: 20 !important;
    }
  }
}

.custom-scrollable--container {
  max-height: 200px;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  @include sf-media('xs') {
    max-height: 120px;
  }

  .scroll-element {
    scroll-snap-align: end;
  }
}

//Table in mobile view
@include sf-media('lt-sm') {
  ::ng-deep .sf-table {
	border: none !important;
    .mdc-data-table {
      &__row {
        @include sf-media('xs') {
          height: auto !important;
          border: 1px solid rgba($sf-black, 0.12);
          border-radius: 4px;
          margin-bottom: 12px;
          background-color: rgba($sf-black, 0.05) !important;
        }
      }
      &__cell {
        @include sf-media('xs') {
          margin: -6px 0;
        }
      }
      &__table {
        white-space: unset;
        border-collapse: unset;
      }
    }

	.sf-table__row {
	  display: flex;
	  flex-direction: column !important;
	  height: auto;
	  padding: 8px 0;
	}

	.sf-table__header-row {
	  display: none !important;
	}

	.sf-table__cell {
	  display: flex;
	  height: fit-content;
	  padding: 8px 20px !important;
	  place-content: start space-between !important;
	  &::before {
		content: attr(datatype);
		float: left;
		margin-right: 20px;
		white-space: nowrap;
	  }
	  &:last-child {
		border-bottom: 0;
	  }
	}
  }
}

::ng-deep .search .mdc-text-field .mdc-floating-label:not(.mdc-floating-label--float-above) {
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 80px);
}

.support-text {
  @include sf-media('xs') {
    position: fixed !important;
    bottom: 10px;
    width: 86%;
  }
  &__information {
    position: relative !important;
    border-top: 2px solid rgba($sf-black, 0.12);
  }
  &__image {
    position: absolute !important;
    bottom: 44px;
  }
}

.small-screen-modal {
  @include sf-media('xs') {
    max-width: 100% !important;
    .sf-dialog {
      &__container {
        border-radius: 0;
      }
      &__primary {
        margin: 0 16px;
      }
    }
  }
}

.main-layout__position {
  position: relative;
}

.notification-landing {
  .sf-dialog__primary {
    border-top: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    @include sf-media('xs') {

      width: 100vw;
    }
  }
}
